import { default as index6mdja2hatwMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/index.vue?macro=true";
import { default as packages6Ndx14ObMKMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/packages.vue?macro=true";
import { default as partsUQMSkmjCwtMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/parts.vue?macro=true";
import { default as customs8bJiauGqaMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom.vue?macro=true";
import { default as indexHd2cSZnSLFMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/index.vue?macro=true";
import { default as rateshoOc6zmkRAMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/rates.vue?macro=true";
import { default as subscriptionXVvboR1M0sMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/subscription.vue?macro=true";
import { default as accountRXk3IrnltHMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account.vue?macro=true";
import { default as _91id_93Wm2MNwgaVhMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexDG07Ar0bSNMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93Lf2QC7822vMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93xS8VTG7o1XMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as index3IU3jSImkMMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculeskXWA5WvI17Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93nqgwnWqFHGMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexHv7Uot9eFUMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/index.vue?macro=true";
import { default as productsuprBk2XUayMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/products.vue?macro=true";
import { default as indexIAswoNaJcBMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/index.vue?macro=true";
import { default as configurationkrz0GY6twFMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexzEQ61gC22qMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planj0RkEhNXtVMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksnhxVbTEp4WMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenance8eU2NyqU80Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsbbSZzprIjmMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/manuals.vue?macro=true";
import { default as times3xFJNzWEHMMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/times.vue?macro=true";
import { default as catalogUYrgLXg7YYMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog.vue?macro=true";
import { default as commandeCXapNKRUmHMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/commande.vue?macro=true";
import { default as _91tab_93qkl7GafQXhMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93uDMt1z3VNvMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93LRRlkGCWoOMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfaZlqxBavKvMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/imprimer-pdf.vue?macro=true";
import { default as index1r05zj2U2OMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/index.vue?macro=true";
import { default as loginja3W6WoPfdMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/login.vue?macro=true";
import { default as panier3gt7V00pc0Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/panier.vue?macro=true";
import { default as indexgobdBRUY5aMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsfmnzZbvIKHMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiquemRAFJg68LiMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique.vue?macro=true";
import { default as maintenanceXUl5F98HeOMeta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/print/maintenance.vue?macro=true";
import { default as productsswUe1JN4J2Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/products.vue?macro=true";
import { default as signupe0jVbWFLR8Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordR0SatPcAx1Meta } from "/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountRXk3IrnltHMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account.vue"),
    children: [
  {
    name: customs8bJiauGqaMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/boutique/products.vue")
  },
  {
    name: catalogUYrgLXg7YYMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/index.vue")
  },
  {
    name: maintenance8eU2NyqU80Meta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfaZlqxBavKvMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginja3W6WoPfdMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/panier.vue")
  },
  {
    name: pneumatiquemRAFJg68LiMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceXUl5F98HeOMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupe0jVbWFLR8Meta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/autopartspro.fr/app.autopartspro.fr/pages/tableau-de-bord.vue")
  }
]